import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';



class Footer12 extends Component {

    state = {
        fixed:false
    }

    componentDidMount() {

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
                //console.log("active")
            } else{
                current.classList.add('open');
                //console.log("close")
            }
        }

    }

    handleStateChange = (status)=> {
        const fixed = status.status === Sticky.STATUS_FIXED ? true : false
        
        this.setState({
            fixed
        })
        
    }

    render() {
        const active = this.state.fixed ? "is-fixed" : ""
        return (
            <>
            <Sticky 
            innerZ={999} 
            enabled={true}
            onStateChange={this.handleStateChange}
            >
                <header className="site-header mo-left header header-transparent navstyle2">
                    <div class={`sticky-header main-bar-wraper ${active} navbar-expand-lg`}>
                        <div className="main-bar clearfix ">
                            <div className="container clearfix">
                                
                                <div className="logo-header mostion hidden hero-content flex" >
                                    <Link  className="" to="/">  <h1 className="m-auto text-white">Akdeniz</h1></Link>
                                </div>
                                
                                <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                
                            
                           
                            </div>
                        </div>
                    </div>
                </header>
            </Sticky>                
            </>
        )
    }
}
export default Footer12;